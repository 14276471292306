import { IEntityDefinitions, IEntityFieldDefinition } from './interface';
const linkWidgetFieldDef: IEntityFieldDefinition = {
  type: 'link',
  editable: true,
  options: {
    labelOptions: {
      label: 'Button label',
      fieldName: 'attributes.field_link_text',
    },
    externalOptions: {
      label: 'External URL',
      fieldName: 'attributes.field_link_external.uri',
    },
    internalOptions: {
      label: 'Internal URL',
      fieldName: 'attributes.field_link_internal',
    },
  },
};

const genericAudioTypes = [
  ['audio/mpeg ', '.mp3'],
  ['audio/wav', '.wav'],
  ['audio/x-wav', '.wav'],
  ['wma', '.wma'],
  ['acc', '.acc'],
];

const genericMimeTypes = [
  ['image/png', '.png'],
  ['image/tiff', '.tif'],
  ['image/tiff', '.tiff'],
  ['image/jpeg', '.jpg'],
  ['image/jpeg', '.jpeg'],
  ['application/postscript', '.eps'],
  ['image/svg+xml', '.svg'],
  ['video/quicktime', '.mov'],
  ['video/mp4', '.mp4'],
  ['application/pdf', '.pdf'],
  ['application/zip', '.zip'],
  ['text/plain', '.txt'],
  ['application/msword', '.doc'],
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', '.docx'],
  ['.xls'],
  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.xlsx'],
  ['application/vnd.ms-powerpoint', '.ppt'],
  ['application/vnd.openxmlformats-officedocument.presentationml.presentation', '.pptx'],
  ['application/msword', '.dot'],
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.template', '.dotx'],
  ['.xlt'],
  ['application/vnd.openxmlformats-officedocument.spreadsheetml.template', '.xltx'],
  ['.pot'],
  ['application/vnd.openxmlformats-officedocument.presentationml.template', '.potx'],
  ['.ase'],
  ['.indd'],
  ['.indt'],
  ['.eml'],
];

/**
 * Module definitions.
 *
 * These are used by the functions in `helpers.ts` to construct concrete entity
 * definitions. See the `interface.ts` file for type information.
 *
 * The keys are the entity types (wildcards are supported, see the first item)
 *
 * If something (key or property) begins with a `$$` it will be processed in
 * some way by the helper functions before being exposed.
 */
export const entityDefinitions: IEntityDefinitions = {
  'paragraph--*': {
    meta: {
      name: 'Module',
      description: 'A module with no description',
      icon: true,
      iconName: 'modules-1',
      categories: {
        content: true,
      },
    },
    fields: {
      'attributes.field_text.value': {
        editable: true,
        type: 'html',
        options: {
          excludeControls: ['headlineOne', 'headlineTwo'],
        },
      },
      'attributes.field_title.value': {
        editable: true,
        tagName: 'h3',
        type: 'html',
        options: {
          excludeControls: ['xL', 'orderedList', 'unorderedList'],
        },
      },
      'relationships.field_image': {
        editable: true,
        type: 'image',
        options: {
          placeholder: true,
          mimeTypes: [
            ['image/png', '.png'],
            ['image/jpeg', '.jpg/.jpeg'],
            ['image/gif', '.gif'],
            ['image/svg+xml', '.svg'],
          ],
        },
      },
    },

    settings: {
      details: {
        weight: -1,
        title: 'Details',
        fields: {
          'attributes.field_hide_title': {
            weight: -1,
            type: 'checkbox',
            options: {
              label: 'Hide module headline',
            },
          },
          'attributes.field_pdf_excluded': {
            weight: -1,
            type: 'checkbox',
            options: {
              label: "Don't show this module in PDF",
            },
          },
          'attributes.field_pdf_page_break': {
            weight: -1,
            type: 'checkbox',
            options: {
              label: 'Start new page in PDF with this module',
            },
          },
        },
      },
      assets: {
        title: 'Attachments',
        weight: 10,
        fields: {
          'attributes.field_mam_assets': {
            type: 'mamIds',
            options: {
              label: 'MAM IDs',
            },
          },
          'relationships.field_assets.data': {
            type: 'upload',
            multiple: true,
            options: {
              label: 'Uploads',
              mimeTypes: genericMimeTypes,
            },
          },
        },
      },
    },
  },

  // keyed by entity type
  'paragraph--introduction': {
    meta: {
      name: 'Introduction',
      iconName: 'introduction',
    },
    fields: {
      'attributes.field_title.value': {
        tagName: 'h1',
        type: 'html',
      },
    },
    settings: {
      details: {
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      // DK NOTE: functionality dosen't work as expected
      /* layout: {
        title: 'Layout',
        fields: {
          'attributes.field_image_pos_vertical': {
            type: 'radio',
            options: {
              values: [
                { label: 'Headline below image', value: 1 },
                { label: 'Headline over image', value: 2 }
              ]
            }
          }
        }
      }, */
      assets: null,
    },
  },
  'paragraph--moodboard': {
    meta: {
      name: 'Moodboard',
      iconName: 'moodboard',
    },
    fields: {
      'attributes.field_text.value': null,
      'relationships.field_image': null,
    },
    settings: {
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_layout': {
            type: 'radio',
            label: 'Layout',
            options: {
              values: [
                /*
                  DK NOTE: value should be number not string,
                  therefore the api have to be changed to return
                  numbers instead of strings for field_layout.
                 */
                { label: '5 images', value: '1' },
                { label: '4 images', value: '2' },
                { label: '3 images', value: '3' },
              ],
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      assets: null,
    },
  },
  'paragraph--moodboard_image': {
    meta: {
      name: 'Moodboard Image',
      iconName: 'moodboard',
      categories: {
        item: true,
      },
    },
    fields: {
      'attributes.field_title.value': null,
      'attributes.field_text.value': null,
    },
    settings: {
      details: {
        fields: {
          'attributes.field_show_border': {
            type: 'checkbox',
            options: {
              label: 'Show Border',
            },
          },
          'attributes.field_border_radius': {
            type: 'select',
            options: {
              label: 'Rounded corners',
              values: [
                { label: 'None', value: 0 },
                { label: 'Default', value: 1 },
                { label: 'Small', value: 2 },
                { label: 'Medium', value: 3 },
                { label: 'Large', value: 4 },
              ],
            },
          },
          'attributes.field_title.value': {
            type: 'text',
            options: {
              label: 'Title',
            },
          },
        },
      },
      link: {
        title: 'Link options',
        fields: {
          $$linkWidget: linkWidgetFieldDef,
        },
      },
      assets: null,
    },
  },
  'paragraph--slim_link': {
    meta: {
      name: 'Slim Link',
      iconName: 'slimteaser',
    },
    fields: {
      'relationships.field_image': null,
    },
    settings: {
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_slim_link_variant': {
            type: 'radio',
            options: {
              values: [
                { label: 'Text right, Image left', value: 0 },
                { label: 'Text left, Image right', value: 1 },
              ],
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      image: {
        title: 'Image',
        fields: {
          'relationships.field_bg_image.data': {
            type: 'upload',
            multiple: false,
            options: {
              label: 'Preview',
              mimeTypes: [
                ['image/png', '.png'],
                ['image/jpeg', '.jpg/.jpeg'],
                ['image/gif', '.gif'],
              ],
            },
          },
        },
      },
      background: {
        title: 'Colors',
        fields: {
          'attributes.field_link_color': {
            type: 'select',
            options: {
              label: 'Link and text color',
              values: [
                { label: 'Dark', value: 2 },
                { label: 'Light', value: 1 },
              ],
            },
          },
        },
      },
      link: {
        title: 'Link options',
        fields: {
          $$linkWidget: linkWidgetFieldDef,
        },
      },
      details: {
        weight: -1,
        title: 'Details',
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
          'attributes.field_pdf_excluded': {
            editable: false,
            options: {
              disabled: true,
              defaultValue: true,
            },
          },
        },
      },
      assets: null,
    },
  },
  'paragraph--text_headline': {
    meta: {
      name: 'Text Headline',
      iconName: 'text',
    },
    settings: {
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_num_columns': {
            type: 'radio',
            options: {
              values: [
                { label: 'Fullwidth', value: 1 },
                { label: 'Two columns', value: 2 },
                { label: 'Three columns', value: 3 },
              ],
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--teasers': {
    meta: {
      name: 'Teaser',
      iconName: 'teaser',
    },
    fields: {
      'relationships.field_image': null,
    },
    settings: {
      details: {
        weight: -1,
        title: 'Details',
        fields: {
          'attributes.field_pdf_excluded': {
            editable: false,
            options: {
              disabled: true,
              defaultValue: true,
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
          'attributes.field_grid_type': {
            type: 'radio',
            options: {
              values: [
                { label: '3 Teaser', value: 1 },
                { label: '2 Teaser', value: 2 },
              ],
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--teasers_item': {
    meta: {
      name: 'Teasers item',
      iconName: 'teaser',
      categories: {
        item: true,
      },
    },
    settings: {
      link: {
        title: 'Link options',
        fields: {
          $$linkWidget: linkWidgetFieldDef,
        },
      },
      details: {
        weight: -1,
        title: 'Details',
        fields: {
          'attributes.field_pdf_excluded': {
            editable: false,
            options: {
              disabled: true,
              defaultValue: true,
            },
          },
        },
      },
      assets: null,
    },
  },
  'paragraph--annotations': {
    meta: {
      name: 'Annotations',
      iconName: 'annotation',
    },
    fields: {
      'attributes.field_annotations_items': {
        editable: true,
        type: 'plain',
        multiple: true,
        options: {
          excludeControls: [
            'headlineOne',
            'headlineTwo',
            'headlineThree',
            'headlineFour',
            'headlineFive',
            'headlineSix',
            'orderedList',
            'unorderedList',
            'alignLeft',
            'alignCenter',
            'alignRight',
            'xL',
            'sup',
          ],
        },
      },
    },
    settings: {
      details: {
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_annotations_layout': {
            type: 'select',
            options: {
              defaultValue: 0,
              label: 'Image position',
              values: [
                { label: 'Left', value: 0 },
                { label: 'Right', value: 1 },
                { label: 'Top', value: 2 },
                { label: 'Bottom', value: 3 },
              ],
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      assets: null,
    },
  },
  'paragraph--annotations_item': {
    meta: {
      name: 'Annotations Item',
      iconName: 'annotations-item',
      categories: { item: true },
    },
    fields: {
      'attributes.field_annotations_item': {
        editable: true,
        type: 'html',
        multiple: true,
        options: {
          excludeControls: [
            'headlineOne',
            'headlineTwo',
            'headlineThree',
            'headlineFour',
            'headlineFive',
            'headlineSix',
            'orderedList',
            'unorderedList',
            'alignLeft',
            'alignCenter',
            'alignRight',
            'xL',
            'sup',
          ],
        },
      },
    },
    settings: {
      assets: null,
      details: null,
    },
  },
  'paragraph--checklist': {
    meta: {
      name: 'Checklist',
      iconName: 'checklist',
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_checklist_items': {
        editable: true,
        type: 'html',
        multiple: true,
        options: {
          excludeControls: [
            'headlineOne',
            'headlineTwo',
            'headlineThree',
            'headlineFour',
            'headlineFive',
            'headlineSix',
            'orderedList',
            'unorderedList',
            'alignLeft',
            'alignCenter',
            'alignRight',
            'xL',
            'sup',
          ],
        },
      },
    },
    settings: {
      details: {
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  /* DK NOTE: 1xInternet rebuild the Checklist Module but we use the old one because
  we have to update all running drupal backends to use the new module. -> maybe
  we can use the new module later. */
  /* 'paragraph--checklist_item': {
    meta: {
      name: 'Checklist item',
      iconName: 'checklist',
      categories: { item: true }
    },
    settings: {
      assets: null,
      details: null
    }
  }, */
  'paragraph--anchor': {
    meta: {
      name: 'Anchor',
      iconName: 'anchor2',
      categories: {
        content: false,
        structural: true,
      },
    },
    fields: {
      'attributes.field_title.value': {
        tagName: 'h2',
      },
      'attributes.field_text.value': null,
      'relationships.field_image': null,
    },
    settings: {
      details: {
        fields: {
          'attributes.field_hide_title': {
            weight: -1,
            type: 'checkbox',
            options: {
              label: 'Hide Anchor headline',
            },
          },
          'attributes.field_hide_in_menu': {
            type: 'checkbox',
            options: {
              label: "Don't show Anchor in navigation",
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      assets: null,
    },
  },
  'paragraph--text_image': {
    meta: {
      name: 'Text Image',
      iconName: 'textimage',
    },
    settings: {
      link: {
        title: 'Link options',
        fields: {
          $$linkWidget: linkWidgetFieldDef,
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_image_pos': {
            type: 'radio',
            options: {
              values: [
                { label: 'Text right, image left', value: 1 },
                { label: 'Text left, image right', value: 2 },
              ],
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      image: {
        title: 'Image',
        fields: {
          'attributes.field_image_proportions': {
            type: 'select',
            options: {
              defaultValue: 1,
              label: 'Image proportions',
              coerceNumber: true,
              values: [
                { label: '1:1', value: 0 },
                { label: '4:3', value: 1 },
                { label: '16:9', value: 2 },
                { label: '9:16', value: 3 },
                { label: '4:1', value: 4 },
                { label: 'original', value: 5 },
              ],
            },
          },
          'attributes.field_image_size': {
            type: 'select',
            options: {
              defaultValue: 1,
              label: 'Image size',
              coerceNumber: true,
              values: [
                { label: 'Small', value: 1 },
                { label: 'Large', value: 2 },
              ],
            },
          },
          'attributes.field_show_border': {
            type: 'checkbox',
            options: {
              label: 'Show Border',
            },
          },
          'attributes.field_border_radius': {
            type: 'select',
            options: {
              label: 'Rounded corners',
              values: [
                { label: 'None', value: 0 },
                { label: 'Default', value: 1 },
                { label: 'Small', value: 2 },
                { label: 'Medium', value: 3 },
                { label: 'Large', value: 4 },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--palette': {
    meta: {
      name: 'Palette',
      iconName: 'palette',
    },
    fields: {
      'relationships.field_image': null,
    },
    settings: {
      details: {
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_display_type': {
            type: 'radio',
            options: {
              values: [
                { label: 'List view', value: 1 },
                { label: 'Card view', value: 2 },
              ],
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },

  'paragraph--columns': {
    meta: {
      name: 'Columns',
      iconName: 'columns',
    },
    fields: {},
    settings: {
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_num_columns': {
            type: 'radio',
            options: {
              values: [
                { label: '2 Cols', value: 2 },
                { label: '3 Cols', value: 3 },
              ],
            },
          },
          'attributes.field_layout': {
            type: 'radio',
            dependency: 'attributes.field_num_columns',
            options: {
              values: {
                2: [
                  { label: '50% / 50%', value: 1 },
                  { label: '66% / 33%', value: 2 },
                  { label: '33% / 66%', value: 3 },
                ],
                3: [],
              },
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },

  'paragraph--columns_item': {
    meta: {
      name: 'Columns Item',
      iconName: 'columns',
      categories: {
        item: true,
      },
    },
    fields: {},
    settings: {
      assets: null,
      details: null,
      modules: {
        title: 'Modules',
        fields: {
          'relationships.field_modules.data': {
            type: 'module',
            multiple: true,
            options: {
              include: [
                'paragraph--accordion',
                'paragraph--annotations',
                'paragraph--checklist',
                'paragraph--downloads',
                'paragraph--html_inline',
                'paragraph--iframe',
                'paragraph--image_link',
                'paragraph--moodboard',
                'paragraph--palette',
                'paragraph--sketch_library',
                'paragraph--slider',
                'paragraph--slim_link',
                'paragraph--tabs',
                'paragraph--teasers',
                'paragraph--text_headline',
                'paragraph--text_image',
                'paragraph--ui_component',
                'paragraph--video',
                'paragraph--audio',
                // 'paragraph--downloads_with_description'
              ],
            },
          },
        },
      },
    },
  },

  'paragraph--palette_item': {
    meta: {
      name: 'Palette item',
      iconName: 'palette',
      workableReferences: ['field_main_color', 'field_shades'],
      saveReferences: ['field_main_color', 'field_shades'],
      categories: {
        item: true,
      },
    },
    fields: {
      'attributes.field_title.value': null,
      'attributes.field_text.value': null,
      'relationships.field_image': null,
    },
    settings: {
      details: null,
      assets: null,
    },
  },

  'paragraph--color': {
    meta: {
      name: 'Color',
      iconName: 'palette',
      categories: {
        item: true,
      },
    },
    fields: {
      'attributes.field_title.value': null,
      'attributes.field_text.value': null,
      'relationships.field_image': null,
    },
    settings: {
      details: {
        fields: {
          'attributes.field_light_color': {
            type: 'checkbox',
            options: {
              label: 'Dark font colour',
            },
          },
          'attributes.field_title.value': {
            type: 'text',
            options: {
              label: 'Title',
            },
          },
          'attributes.field_cmyk': {
            type: 'text',
            options: {
              label: 'CMYK',
            },
          },
          'attributes.field_hex': {
            type: 'text',
            options: {
              label: 'HEX',
            },
          },
          'attributes.field_ral': {
            type: 'text',
            options: {
              label: 'RAL',
            },
          },
          'attributes.field_rgb': {
            type: 'text',
            options: {
              label: 'RGB',
            },
          },
          'attributes.field_ncs': {
            type: 'text',
            options: {
              label: 'NCS',
            },
          },
          'attributes.field_pantone': {
            type: 'text',
            options: {
              label: 'Pantone',
            },
          },
          'attributes.field_avery': {
            type: 'text',
            options: {
              label: 'AVERY',
            },
          },
          'attributes.field_textil': {
            type: 'text',
            options: {
              label: 'Textil',
            },
          },
          'attributes.field_hks': {
            type: 'text',
            options: {
              label: 'HKS',
            },
          },
          'attributes.field_default': {
            type: 'checkbox',
            options: {
              label: 'Default',
            },
          },
        },
      },
      assets: null,
    },
  },

  'paragraph--tabs': {
    meta: {
      name: 'Tabs',
      iconName: 'tabs',
      workableReferences: ['field_items'],
      saveReferences: ['field_items'],
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_text.value': null,
    },
    settings: {
      assets: null,
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--tabs_item': {
    meta: {
      name: 'Tabs item',
      iconName: 'tabs',
      categories: {
        item: true,
      },
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_text.value': null,
      'attributes.field_title.value': {
        tagName: 'span',
      },
    },
    settings: {
      assets: null,
      details: null,
      modules: {
        title: 'Modules',
        fields: {
          'relationships.field_modules.data': {
            type: 'module',
            multiple: true,
            options: {
              include: [
                'paragraph--accordion',
                'paragraph--annotations',
                'paragraph--bgcontainer',
                'paragraph--checklist',
                'paragraph--columns',
                'paragraph--downloads',
                'paragraph--html_inline',
                'paragraph--iframe',
                'paragraph--image_link',
                'paragraph--moodboard',
                'paragraph--palette',
                'paragraph--sketch_library',
                'paragraph--slider',
                'paragraph--slim_link',
                'paragraph--tabs',
                'paragraph--teasers',
                'paragraph--text_headline',
                'paragraph--text_image',
                'paragraph--ui_component',
                'paragraph--video',
                'paragraph--audio',
                // 'paragraph--downloads_with_description'
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--accordion': {
    meta: {
      name: 'Accordion',
      iconName: 'accordion',
    },
    fields: {
      'attributes.field_text.value': null,
    },
    settings: {
      assets: null,
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },

  'paragraph--accordion_item': {
    meta: {
      name: 'Accordion Item',
      iconName: 'accordion',
      categories: {
        item: true,
      },
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_text.value': null,
      'attributes.field_title.value': {
        tagName: 'div',
      },
    },
    settings: {
      assets: null,
      modules: {
        title: 'Modules',
        fields: {
          'relationships.field_modules.data': {
            type: 'module',
            multiple: true,
            options: {
              include: [
                'paragraph--accordion',
                'paragraph--checklist',
                'paragraph--downloads_with_description',
                'paragraph--downloads',
                'paragraph--image_link',
                'paragraph--icon_library',
                'paragraph--moodboard',
                'paragraph--slider',
                'paragraph--slim_link',
                'paragraph--teasers',
                'paragraph--text_headline',
                'paragraph--text_image',
                'paragraph--video',
                'paragraph--audio',
                'paragraph--columns',
              ],
            },
          },
        },
      },
    },
  },

  'paragraph--slider': {
    meta: {
      name: 'Slider',
      iconName: 'slider',
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_text.value': null,
    },
    settings: {
      details: {
        fields: {
          'attributes.field_duration': {
            type: 'number',
            options: {
              label: 'Autoplay Speed',
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      assets: null,
    },
  },
  'paragraph--slider_item': {
    meta: {
      name: 'Slider item',
      iconName: 'slider',
      categories: {
        item: true,
      },
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_text.value': null,
    },
    settings: {
      assets: null,
      modules: {
        title: 'Modules',
        fields: {
          'relationships.field_modules.data': {
            type: 'module',
            multiple: true,
            options: {
              include: [
                'paragraph--text_image',
                'paragraph--text_headline',
                'paragraph--moodboard',
                'paragraph--slim_link',
                'paragraph--video',
                'paragraph--audio',
                'paragraph--image_link',
                'paragraph--columns',
                // 'paragraph--downloads_with_description'
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--downloads': {
    meta: {
      name: 'Download',
      iconName: 'download-list',
    },
    fields: {
      'relationships.field_image': null,
    },
    settings: {
      assets: null,
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      details: {
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
          'attributes.field_mam_assets': {
            type: 'mamIds',
            options: {
              label: 'MAM IDs',
            },
          },
          'relationships.field_downloads.data': {
            type: 'upload',
            multiple: true,
            options: {
              label: 'Files',
              mimeTypes: genericMimeTypes,
            },
          },
          'attributes.field_pdf_excluded': {
            editable: false,
            options: {
              disabled: true,
              defaultValue: true,
            },
          },
        },
      },
    },
  },
  'paragraph--downloads_with_description': {
    meta: {
      name: 'Downloads with Desc',
      iconName: 'download-list',
    },
    settings: {
      details: {
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
          'attributes.field_pdf_excluded': {
            editable: false,
            options: {
              disabled: true,
              defaultValue: true,
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      assets: null,
    },
  },
  'paragraph--file': {
    meta: {
      name: 'File',
      iconName: 'download-list',
      categories: { generic: true },
    },
    settings: {
      assets: null,
      details: null,
      file: {
        fields: {
          'attributes.field_mam_assets': {
            type: 'mamIds',
            options: {
              label: 'MAM ID',
            },
          },
          'relationships.field_file.data': {
            type: 'upload',
            multiple: false,
            options: {
              label: 'File',
              mimeTypes: genericMimeTypes,
            },
          },
        },
      },
    },
  },
  'paragraph--video': {
    meta: {
      name: 'Video',
      iconName: 'video',
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_caption.value': {
        editable: true,
        type: 'html',
        options: {
          excludeControls: [
            'headlineOne',
            'headlineTwo',
            'headlineFour',
            'headlineFive',
            'headlineSix',
            'orderedList',
            'unorderedList',
            'alignLeft',
            'alignCenter',
            'alignRight',
          ],
        },
      },
    },
    settings: {
      details: {
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
          'attributes.field_hide_caption': {
            type: 'checkbox',
            options: {
              label: 'Hide caption',
            },
          },
          'relationships.field_image.data': {
            type: 'upload',
            multiple: false,
            options: {
              label: 'Preview',
              mimeTypes: [
                ['image/png', '.png'],
                ['image/jpeg', '.jpg/.jpeg'],
                ['image/gif', '.gif'],
              ],
            },
          },
          'relationships.field_webm.data': {
            type: 'upload',
            multiple: false,
            options: {
              label: 'Webm',
              mimeTypes: [['video/webm', '.webm']],
            },
          },
          'relationships.field_mpeg4.data': {
            type: 'upload',
            multiple: false,
            options: {
              label: 'Mp4',
              mimeTypes: [['video/mp4', '.mp4']],
            },
          },
          'attributes.field_pdf_excluded': {
            editable: false,
            options: {
              disabled: true,
              defaultValue: true,
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_full_width': {
            type: 'checkbox',
            options: {
              label: 'Show full width',
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--audio': {
    meta: {
      name: 'Audio',
      iconName: 'audio',
    },
    fields: {
      'attributes.field_assets': { editable: true, type: 'html' },
      'attributes.field_caption.value': {
        editable: true,
        type: 'html',
        options: {
          excludeControls: [
            'headlineOne',
            'headlineTwo',
            'headlineFour',
            'headlineFive',
            'headlineSix',
            'orderedList',
            'unorderedList',
            'alignLeft',
            'alignCenter',
            'alignRight',
          ],
        },
      },
    },
    settings: {
      details: {
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
          'attributes.field_hide_caption': {
            type: 'checkbox',
            options: {
              label: 'Hide caption',
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      assets: null,
      files: {
        title: 'Audio File',
        weight: 10,
        fields: {
          'relationships.field_file.data': {
            type: 'upload',
            multiple: false,
            options: {
              label: 'Upload',
              mimeTypes: genericAudioTypes,
            },
          },
        },
      },
    },
  },
  'paragraph--image_link': {
    meta: {
      name: 'Image',
      iconName: 'image',
    },
    fields: {
      'attributes.field_caption.value': {
        editable: true,
        type: 'html',
        options: {
          excludeControls: ['headlineOne', 'headlineTwo', 'headlineFour', 'headlineFive', 'headlineSix', 'xL'],
        },
      },
    },

    settings: {
      image: {
        title: 'Image',
        fields: {
          'attributes.field_image_proportions': {
            type: 'select',
            options: {
              defaultValue: 1,
              label: 'Image proportions',
              coerceNumber: true,
              values: [
                { label: '1:1', value: 0 },
                { label: '4:3', value: 1 },
                { label: '16:9', value: 2 },
                { label: '9:16', value: 3 },
                { label: '4:1', value: 4 },
                { label: 'original', value: 5 },
              ],
            },
          },
        },
      },
      details: {
        fields: {
          'attributes.field_show_border': {
            type: 'checkbox',
            options: {
              label: 'Show Border',
            },
          },
          'attributes.field_border_radius': {
            type: 'select',
            options: {
              label: 'Rounded corners',
              values: [
                { label: 'None', value: 0 },
                { label: 'Default', value: 1 },
                { label: 'Small', value: 2 },
                { label: 'Medium', value: 3 },
                { label: 'Large', value: 4 },
              ],
            },
          },
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide text',
            },
          },
          'attributes.field_hide_caption': {
            type: 'checkbox',
            options: {
              label: 'Hide caption',
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_full_width': {
            type: 'checkbox',
            options: {
              label: 'Show full width',
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },

  'paragraph--wizard': {
    meta: {
      name: 'Wizard',
      iconName: 'tabs',
      workableReferences: ['field_modules'],
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_text.value': null,
    },
    settings: {
      assets: null,
      details: {
        title: 'Details',
        fields: {
          'attributes.field_level_def': {
            type: 'text',
            options: {
              label: 'Levels definition',
            },
          },
        },
      },
      modules: {
        title: 'Modules',
        fields: {
          'relationships.field_modules.data': {
            type: 'module',
            multiple: true,
            options: {
              exclude: ['$$generic', '$$item', 'paragraph--wizard'],
            },
          },
        },
      },
    },
  },
  'paragraph--bgcontainer': {
    meta: {
      name: 'BG Container',
      iconName: 'tabs',
      workableReferences: ['field_modules'],
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_text.value': null,
    },
    settings: {
      assets: null,
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_css_color_class': {
            type: 'select',
            options: {
              label: 'Background color',
              values: [
                { label: '(none)', value: 'bg-container-color-none' },
                { label: 'Custom Color 1', value: 'bg-container-color-1' },
                { label: 'Custom Color 2', value: 'bg-container-color-2' },
              ],
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
      modules: {
        title: 'Modules',
        fields: {
          'relationships.field_modules.data': {
            type: 'module',
            multiple: true,
            options: {
              exclude: ['$$generic', '$$item', 'paragraph--bgcontainer'],
            },
          },
        },
      },
    },
  },
  'paragraph--level': {
    meta: {
      name: 'Level',
      iconName: 'tabs',
      workableReferences: ['field_modules'],
      categories: {
        item: true,
      },
    },
    fields: {
      'relationships.field_image': null,
      'attributes.field_text.value': null,
    },
    settings: {
      assets: null,
      modules: {
        title: 'Modules',
        fields: {
          'relationships.field_modules.data': {
            type: 'module',
            multiple: true,
            options: {
              exclude: ['$$generic', '$$item'],
            },
          },
        },
      },
    },
  },
  'paragraph--explore_brands': {
    meta: {
      name: 'Explore brands',
    },
    fields: {
      'attributes.field_title.value': {
        tagName: 'h3',
        options: {
          excludeControls: [
            'headlineOne',
            'headlineTwo',
            'headlineThree',
            'headlineFour',
            'headlineFive',
            'headlineSix',
            'xL',
            'sup',
            'link',
            'orderedList',
            'unorderedList',
            'alignLeft',
            'alignCenter',
            'alignRight',
          ],
        },
      },
    },
    settings: {
      assets: null,
    },
  },
  'paragraph--explore_brands_item': {
    meta: {
      name: 'Explore brands item',
    },
    fields: {
      'attributes.field_title.value': {
        tagName: 'div',
      },
    },
    settings: {
      link: {
        title: 'Link options',
        fields: {
          $$linkWidget: linkWidgetFieldDef,
        },
      },
      assets: null,
    },
  },
  'node--menu': {
    meta: {
      name: 'Menu',
      hideSettings: true,
      saveReferences: ['field_modules'],
      categories: {
        content: false,
      },
    },
  },
  'node--page': {
    meta: {
      name: 'Page',
      description: 'Page settings',
      categories: {
        content: false,
      },
    },
    fields: {
      'relationships.field_meta_image': {
        editable: true,
        type: 'image',
        options: {
          placeholder: true,
          mimeTypes: [
            ['image/png', '.png'],
            ['image/jpeg', '.jpg/.jpeg'],
          ],
        },
      },
    },
    settings: {
      details: {
        title: 'Details',
        fields: {},
      },
      modules: {
        title: 'Modules',
        fields: {
          'relationships.field_modules.data': {
            type: 'module',
            multiple: true,
            options: {
              exclude: ['$$generic', '$$item'],
            },
          },
        },
      },
    },
  },
  'file--file': {
    meta: {
      name: 'File',
      categories: {
        generic: true,
      },
    },
  },
  'node--page.empty': {
    meta: {
      name: 'Empty page',
      categories: {
        generic: true,
      },
    },
  },
  'paragraph--html_inline': {
    meta: {
      name: 'HTML inline',
      iconName: 'html-inline',
    },
    fields: {
      'attributes.field_title.value': {},
      'attributes.field_html_source_code': {
        type: 'text',
      },
    },
    settings: {
      assets: null,
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--ui_component': {
    meta: {
      name: 'UI Component',
      iconName: 'ui-component',
    },
    fields: {
      'attributes.field_title.value': {},
      'attributes.field_text.value': {},
      'attributes.field_html_source_code': {
        type: 'text',
      },
    },
    settings: {
      assets: null,
      details: {
        fields: {
          'attributes.field_hide_text': {
            type: 'checkbox',
            options: {
              label: 'Hide description',
            },
          },
          'attributes.field_show_code_by_default': {
            type: 'checkbox',
            options: {
              label: 'Show code by default',
            },
          },
          'attributes.field_link_github': {
            type: 'text',
            options: {
              label: 'Link to GitHub',
            },
          },
          'attributes.field_link_codepen': {
            type: 'text',
            options: {
              label: 'Link to Codepen',
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_layout': {
            type: 'radio',
            options: {
              values: [
                { label: 'Default', value: 1 },
                { label: 'Description | Preview', value: 2 },
                { label: 'Preview | Description', value: 3 },
              ],
            },
          },
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--ui_component_css': {
    meta: {
      name: 'UI Component CSS',
      iconName: 'ui-component-css',
    },
    fields: {
      'attributes.field_css_source_code': {
        type: 'plain',
      },
    },
    settings: {
      assets: null,
      file: {
        fields: {
          'relationships.field_file.data': {
            type: 'upload',
            multiple: false,
            options: {
              label: 'CSS File',
              mimeTypes: [['text/css', '.css']],
            },
          },
        },
      },
    },
  },

  'paragraph--sketch_library': {
    meta: {
      name: 'Sketch Library',
      iconName: 'sketch',
    },
    fields: {
      'attributes.field_title.value': {
        type: 'plain',
      },
      'attributes.field_text.value': {
        type: 'plain',
      },
      'attributes.field_version': {
        type: 'plain',
      },
    },
    settings: {
      assets: null,
      file: {
        fields: {
          'relationships.field_assets.data': {
            type: 'upload',
            multiple: false,
            options: {
              label: 'Sketch File',
              mimeTypes: [['.sketch']],
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'search--result-item': {
    meta: {
      name: 'Search result item',
      hideSettings: true,
      categories: {
        content: false,
      },
    },
    fields: {
      'attributes.title': {},
      'attributes.field_text.value': {
        type: 'text',
      },
      'attributes.url': {},
    },
    settings: {
      assets: null,
    },
  },

  'paragraph--iframe': {
    meta: {
      name: 'IFrame',
      iconName: 'iframe',
    },
    fields: {
      'attributes.field_link_external': {},
    },
    settings: {
      assets: null,
      details: {
        fields: {
          'attributes.field_show_border': {
            type: 'checkbox',
            options: {
              label: 'Show IFrame Border',
            },
          },
          'attributes.field_min_size': {
            type: 'number',
            options: {
              label: 'Min. Height',
            },
          },
        },
      },
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },

  'paragraph--icon_library': {
    meta: {
      name: 'Icon Library',
      iconName: 'icon-library',
    },
    fields: {
      'attributes.field_configuration': {},
    },
    settings: {
      assets: null,
      details: null,
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--media_gallery': {
    meta: {
      name: 'Media Gallery',
      iconName: 'icon-library',
    },
    fields: {
      'attributes.field_configuration': {},
    },
    settings: {
      assets: null,
      details: null,
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'select',
            options: {
              label: 'Padding top',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'select',
            options: {
              label: 'Padding bottom',
              values: [
                { label: 'Default', value: '' },
                { label: 'Big', value: 'big' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--media_library': {
    meta: {
      name: 'Media Library',
      iconName: 'media-library',
    },
    fields: {
      'attributes.field_configuration': {},
    },
    settings: {
      assets: null,
      details: null,
      layout: {
        title: 'Layout',
        fields: {
          'attributes.field_padding_top': {
            type: 'radio',
            label: 'Padding top',
            options: {
              values: [
                { label: 'Big', value: 'big' },
                { label: 'Default', value: '' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
          'attributes.field_padding_bottom': {
            type: 'radio',
            label: 'Padding bottom',
            options: {
              values: [
                { label: 'Big', value: 'big' },
                { label: 'Default', value: '' },
                { label: 'Small', value: 'small' },
                { label: 'None', value: 'none' },
              ],
            },
          },
        },
      },
    },
  },
  'paragraph--newsfeed': {
    meta: {
      name: 'Newsfeed',
      iconName: 'text',
    },
    fields: {
      'attributes.field_title.value': {
        type: 'plain',
      },
    },
    settings: {
      assets: null,
      details: null,
    },
  },
};

export default entityDefinitions;
